import { css } from "@emotion/react";
import { Grid } from "@mui/material";
import { NextPage } from "next";
import React from "react";

import LoginContainer from "@/components/page-login/LoginContainer";

const Index: NextPage = () => {
  return (
    <Grid css={AuthContainer} container justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <LoginContainer />
      </Grid>
    </Grid>
  );
};

export default Index;

const AuthContainer = css`
  z-index: -10;
  background-image: url("wave-haikei.svg");
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const getServerSideProps = async () => ({
  props: { noHeader: true },
});
